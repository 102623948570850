import axios from '@axios'

export default {
  createProductLink(params) {
    return axios.put('/api/users/product-profile', params)
  },

  createProductVideo(params) {
    return axios.post('/api/users/product-profile/videos', params)
  },
}
