import { Pixel } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getPixels({ commit }, params) {
    commit('GET_PIXELS_REQUEST')
    try {
      const { data } = await Pixel.getPixels(params)
      commit('GET_PIXELS_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_PIXELS_FAILURE', error)
    }
  },

  async getPixel({ commit }, params) {
    commit('GET_PIXEL_REQUEST')
    try {
      const { data } = await Pixel.getPixel(params)
      commit('GET_PIXEL_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_PIXEL_FAILURE', error)
    }
  },
  async attachPixel({ commit }, params) {
    commit('ATTACH_PIXEL_REQUEST')
    try {
      const data = await Pixel.attachPixel(params)
      commit('ATTACH_PIXEL_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('ATTACH_PIXEL_FAILURE', error)
    }
  },

  async queryPixel({ commit }, params) {
    commit('QUERY_PIXEL_REQUEST')
    try {
      const { data } = await Pixel.queryPixel(params)
      commit('QUERY_PIXEL_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('QUERY_PIXEL_FAILURE', error)
    }
  },

  async addNewStore({ commit }, params) {
    commit('ADD_NEW_STORE_REQUEST')
    try {
      const { data } = await Pixel.addNewStore(params)
      commit('ADD_NEW_STORE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('ADD_NEW_STORE_FAILURE', error)
    }
  },

  async generatePixel({ commit }, params) {
    commit('GENERATE_PIXEL_CODE_REQUEST')
    try {
      const { data } = await Pixel.generatePixel(params)
      commit('GENERATE_PIXEL_CODE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GENERATE_PIXEL_CODE_FAILURE', error)
    }
  },

  async changeStatus({ commit }, params) {
    commit('CHANGE_STATUS_REQUEST')
    try {
      const { data } = await Pixel.changeStatus(params)
      commit('CHANGE_STATUS_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('CHANGE_STATUS_FAILURE', error)
    }
  },

  async deletePixel({ commit }, params) {
    commit('DELETE_REQUEST')
    try {
      const { data } = await Pixel.deletePixel(params)
      commit('DELETE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('DELETE_FAILURE', error)
    }
  },

  async resetDetail({ commit }) {
    commit('RESET_PIXEL')
  },

  async resetNewStore({ commit }) {
    commit('RESET_NEW_STORE')
  },

  async checkEnableEmbedStore({ commit }, params) {
    commit('CHECK_ENABLED_REQUEST', true)
    try {
      const { data } = await Pixel.checkEnableEmbedStore(params)
      commit('CHECK_ENABLED_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('CHECK_ENABLED_FAILURE', error)
    }
  },

  async enabledExtension({ commit }, params) {
    commit('ENABLE_EXTENSION_REQUEST')
    try {
      const { data } = await Pixel.enabledExtension(params)
      commit('ENABLE_EXTENSION_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('ENABLE_EXTENSION_FAILURE', error)
    }
  },

  async getRetrieveSettings({ commit }, params) {
    commit('GET_RETRIEVE_SETTINGS_REQUEST')
    try {
      const { data } = await Pixel.getRetrieveSetting(params)
      commit('GET_RETRIEVE_SETTINGS_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_RETRIEVE_SETTINGS_FAILURE', error)
    }
  },
}
