import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// using bootstrap vue icons
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import i18n from '@/libs/i18n'
import * as Sentry from '@sentry/vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
// import '@/libs/stripe-plugin'
import '@/libs/vue-device-detector'
import '@/libs/vue-meta'

// TODO: Put back the Facebook
// import '@/libs/vue-fb-customer-chat'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// Composition API
Vue.use(VueCompositionAPI)

// Lottie

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Sentry.init({
  Vue,
  environment: process.env.VUE_APP_NODE_ENV,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [`${process.env.VUE_APP_SERVER_API}/api`],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
