import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  listAccount: state => state.listAccount,
  listTicket: state => state.listTicket,
  listBusinessAccounts: state => state.listBusinessAccounts,
  listAdsAccount: state => state.listAdsAccount,
  tiktokInfo: state => state.tiktokInfo,
  tiktokAccountCurrent: state => state.tiktokAccountCurrent,
}
