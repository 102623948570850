import { ReferralProgramService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getReferralStats({ commit }) {
    commit('GET_STATS_REQUEST')
    try {
      const { data } = await ReferralProgramService.getReferralStats()
      commit('GET_STATS_SUCCESS', data)
    } catch (error) {
      commit('GET_STATS_FAILURE', error)
    }
  },
  async track({ commit }, { params }) {
    commit('TRACK_REQUEST')
    try {
      await ReferralProgramService.track(params)
      commit('TRACK_SUCCESS')
    } catch (error) {
      commit('TRACK_FAILURE', error)
    }
  },

  async getListMemberPartner({ commit }, params) {
    commit('GET_MEMBER_PARTNER_REQUEST')
    try {
      const { data } = await ReferralProgramService.getListMemberPartner(params)
      commit('GET_MEMBER_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_PARTNER_FAILURE', error)
    }
  },

  async getListMemberPartnerActivated({ commit }, params) {
    commit('GET_MEMBER_PARTNER_ACTIVATED_REQUEST')
    try {
      const { data } = await ReferralProgramService.getListMemberPartner(params)
      commit('GET_MEMBER_PARTNER_ACTIVATED_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_PARTNER_ACTIVATED_FAILURE', error)
    }
  },

  async getListMemberPartnerBlocked({ commit }, params) {
    commit('GET_MEMBER_PARTNER_BLOCKED_REQUEST')
    try {
      const { data } = await ReferralProgramService.getListMemberPartner(params)
      commit('GET_MEMBER_PARTNER_BLOCKED_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_PARTNER_BLOCKED_FAILURE', error)
    }
  },

  async getListMemberAds({ commit }, params) {
    commit('GET_LIST_MEMBER_ADS_REQUEST')
    try {
      const { data } = await ReferralProgramService.getListMemberAds(params)
      commit('GET_LIST_MEMBER_ADS_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_MEMBER_ADS_FAILURE', error)
    }
  },

  async getMemberDetail({ commit }, params) {
    commit('GET_MEMBER_DETAIL_REQUEST')
    try {
      const { data } = await ReferralProgramService.getMemberInfo(params)
      commit('GET_MEMBER_DETAIL_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_DETAIL_FAILURE', error)
    }
  },

  async removeMemberPartner({ commit }, params) {
    commit('REMOVE_MEMBER_PARTNER_REQUEST')
    try {
      await ReferralProgramService.removeMemberPartner(params)
      commit('REMOVE_MEMBER_PARTNER_SUCCESS')
    } catch (error) {
      commit('REMOVE_MEMBER_PARTNER_FAILURE', error)
    }
  },

  async handleProductLink({ commit }, params) {
    commit('HANDLE_PRODUCT_LINK_REQUEST')
    try {
      await ReferralProgramService.handleProductLink(params)
      commit('HANDLE_PRODUCT_LINK_SUCCESS')
    } catch (error) {
      commit('HANDLE_PRODUCT_LINK_FAILURE', error)
    }
  },

  async getListProductLinks({ commit }, params) {
    commit('GET_MEMBER_PRODUCT_LINK_REQUEST')
    try {
      const { data } = await ReferralProgramService.getListMemberPartner(params)
      commit('GET_MEMBER_PRODUCT_LINK_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_MEMBER_PRODUCT_LINK_FAILURE', error)
    }
  },
  async getPlanChanged({ commit }, params) {
    commit('GET_PLAN_CHANGED_REQUEST')

    try {
      const { data } = await ReferralProgramService.getPlanChanged(params)
      commit('GET_PLAN_CHANGED_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_PLAN_CHANGED_FAILURE', error)
    }
  },

  async getUserAnalytics({ commit }, params) {
    commit('GET_USER_ANALYTICS_REQUEST')
    try {
      const { data } = await ReferralProgramService.getMemberStatistic(params)
      commit('GET_USER_ANALYTICS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_USER_ANALYTICS_FAILURE', error)
    }
  },

  async getAdsAccounts({ commit }, params) {
    commit('GET_ADS_ACCOUNTS_REQUEST')
    try {
      const { data } = await ReferralProgramService.getAdsAccount(params)
      commit('GET_ADS_ACCOUNTS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ADS_ACCOUNTS_FAILURE', error)
    }
  },

  async getDeposits({ commit }, params) {
    commit('GET_DEPOSITS_REQUEST')
    try {
      const { data } = await ReferralProgramService.getDeposits(params)
      commit('GET_DEPOSITS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_DEPOSITS_FAILURE', error)
    }
  },

}
