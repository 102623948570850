import { FileService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async uploadFile({ commit }, file) {
    commit('UPLOAD_FILE_REQUEST')
    try {
      const { data } = await FileService.uploadFile(file)
      const url = getResponse(data).imageUrl || ''
      // const url = process.env.VUE_APP_SERVER_API + IMAGE_URL + data.id
      commit('UPLOAD_FILE_SUCCESS', url)
    } catch (error) {
      commit('UPLOAD_FILE_FAILURE', error)
    }
  },

  async uploadVideo({ commit }, { file, config }) {
    commit('UPLOAD_VIDEO_REQUEST')
    try {
      const { data } = await FileService.uploadVideo(file, config)
      const url = getResponse(data) || {}
      commit('UPLOAD_VIDEO_SUCCESS', url)
    } catch (error) {
      commit('UPLOAD_VIDEO_FAILURE', error)
    }
  },
}
