import {
  REQUEST, SUCCESS, FAILURE,
} from '@/plugins/store'
import _find from 'lodash/find'
import _map from 'lodash/map'

export default {

  // create
  CREATE_RECHARGE_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_RECHARGE_SUCCESS(state) {
    SUCCESS(state)
    state.errorPayment.message = ''
  },
  CREATE_RECHARGE_FAILURE(state, error) {
    FAILURE(state, error)
    state.errorPayment.message = error.data?.message
  },

  // add funds, refund ads acc
  CREATE_ADS_RECHARGE_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_ADS_RECHARGE_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_ADS_RECHARGE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // list history
  GET_HISTORY_REQUEST(state) {
    REQUEST(state)
  },
  GET_HISTORY_SUCCESS(state, { payload, data }) {
    SUCCESS(state)
    if (payload.type === 'business') {
      state.listBusinessAccountHistory = data
    } else if (payload.type === 'subscription') {
      state.listBillingHistory = data
    } else if (payload.type === 'refund_user_balance') {
      state.listRefundUserHistory = data
    } else {
      state.listAccountHistory = data
    }
  },
  GET_HISTORY_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // fetch List Add Fund History
  GET_HISTORY_LIMIT_REQUEST(state) {
    REQUEST(state)
  },

  GET_HISTORY_LIMIT_SUCCESS(state, payload) {
    SUCCESS(state)
    state.listBusinessAccountHistoryLimit = payload
  },

  GET_HISTORY_LIMIT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  /*= ============== CREDIT CARD ============== */

  // get list credit card
  GET_LIST_CARD_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_CARD_SUCCESS(state, listCard) {
    SUCCESS(state, true)
    state.listCard = listCard || []
  },
  GET_LIST_CARD_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  // set card default
  async SET_CARD_DEFAULT(state, card) {
    state.listCard = await _map(state.listCard, item => ({
      ...item,
      default: false,
    }))

    const obj = await _find(state.listCard, { paymentMethodId: card.paymentMethodId })
    if (obj) {
      obj.default = true
    }

    return state.listCard
  },

  /*= ============== END CREDIT CARD ============== */

  /*= ============== PAYONEER  ============== */
  CONNECT_PAYONEER_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  CONNECT_PAYONEER_ACCOUNT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.payoneerAccount.clientRedirectUrl = data
  },
  CONNECT_PAYONEER_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  DISCONNECT_PAYONEER_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  DISCONNECT_PAYONEER_ACCOUNT_SUCCESS(state) {
    SUCCESS(state, true)
  },
  DISCONNECT_PAYONEER_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  GET_PAYONEER_BALANCE_REQUEST(state) {
    REQUEST(state)
  },
  GET_PAYONEER_BALANCE_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.payoneerAccount.currentAccounts = data
  },
  GET_PAYONEER_BALANCE_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  // ----
  REQUEST_PAYONEER_PAYMENT_REQUEST(state) {
    REQUEST(state)
  },
  REQUEST_PAYONEER_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.payoneerAccount.request = data
  },
  REQUEST_PAYONEER_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  // ----
  CONFIRM_PAYONEER_PAYMENT_REQUEST(state) {
    REQUEST(state)
  },
  CONFIRM_PAYONEER_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.payoneerAccount.confirm = data
    state.payoneerAccount.request = {}
  },
  CONFIRM_PAYONEER_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  /*= ============== END PAYONEER ============== */

  GET_LIST_TICKET_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_TICKET_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.listTicket = data
  },
  GET_LIST_TICKET_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  /*= ============== AIRWALLEX  ============== */

  REQUEST_AIRWALLEX_PAYMENT(state) {
    REQUEST(state)
  },
  REQUEST_AIRWALLEX_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.airwallexOptions.intent_id = data.paymentId
    state.airwallexOptions.client_secret = data.clientSecret
    state.airwallexOptions.currency = data.currency
    state.airwallexOptions.components = data.components
    state.airwallexOptions.successUrl = data.successUrl
    state.airwallexOptions.failUrl = data.failUrl
    state.airwallexOptions.cancelUrl = data.failUrl
  },
  REQUEST_AIRWALLEX_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  /*= ============== END AIRWALLEX ============== */

  /*= ============== TAZAPAY  ============== */

  REQUEST_TAZAPAY_PAYMENT(state) {
    REQUEST(state)
  },

  REQUEST_TAZAPAY_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.tazapayRequest.clientRedirectUrl = data.data.url
  },

  REQUEST_TAZAPAY_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  GET_TAZAPAY_PAYMENT_REQUEST(state) {
    REQUEST(state)
  },

  GET_TAZAPAY_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.tazapayPending = data
  },

  GET_TAZAPAY_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  /*= ============== END TAZAPAY ============== */

  /*= ============== LIANLIAN  ============== */

  REQUEST_LIANLIAN_PAYMENT(state) {
    REQUEST(state)
  },

  REQUEST_LIANLIAN_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.lianlianRequest.clientRedirectUrl = data?.payload?.lianlianPayload?.requestPaymentUrl
  },

  REQUEST_LIANLIAN_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  GET_LIANLIAN_PAYMENT_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIANLIAN_PAYMENT_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.lianlianPending = data
  },

  GET_LIANLIAN_PAYMENT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  /*= ============== END LIANLIAN ============== */

  /*= ============== LOCAL BANK  ============== */
  GET_LIST_LOCAL_BANK_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_LOCAL_BANK_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.listLocalBank = data
  },

  GET_LIST_LOCAL_BANK_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  RECENT_LOCAL_BANK_NOTIFICATION_REQUEST(state) {
    state.listRecentNotification.loading = true
    state.listRecentNotification.error = null
    state.listRecentNotification.status = false
  },

  RECENT_LOCAL_BANK_NOTIFICATION_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.listRecentNotification.data = data
  },

  RECENT_LOCAL_BANK_NOTIFICATION_FAILURE(state, error) {
    state.listRecentNotification.loading = false
    state.listRecentNotification.error = error
    state.listRecentNotification.status = false
  },
  /*= ============== END LOCAL BANK ============== */

  /*= =============== AUTO TOPUP ==============   */
  AUTO_TOP_UP_AD_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },

  AUTO_TOP_UP_AD_ACCOUNT_SUCCESS(state) {
    SUCCESS(state, true)
  },

  AUTO_TOP_UP_AD_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error, true)
  },
  /*= =============== END AUTO TOPUP ==============   */

  /*= =============== PAY FAST ==============   */
  PAY_FAST_REQUEST(state) {
    REQUEST(state)
  },

  PAY_FAST_REQUEST_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.payFast = data
  },

  PAY_FAST_REQUEST_FAILURE(state, error) {
    FAILURE(state, error, true)
  },
  /*= =============== END PAY FAST ==============   */

  /*= =============== IDEAL ==============   */
  STRIPE_BANK_REDIRECTS_REQUEST(state) {
    REQUEST(state)
  },

  STRIPE_BANK_REDIRECTS_REQUEST_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.stripeBankRedirects = data
  },

  STRIPE_BANK_REDIRECTS_REQUEST_FAILURE(state, error) {
    FAILURE(state, error, true)
  },
  /*= =============== END IDEAL ==============   */

  /*= =============== AUTO REFUND ============== */
  REQUEST_AUTO_REFUND_REQUEST(state) {
    REQUEST(state)
  },

  REQUEST_AUTO_REFUND_SUCCESS(state) {
    SUCCESS(state, true)
  },

  REQUEST_AUTO_REFUND_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  GET_LIST_USED_PAYMENT_METHOD_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_USED_PAYMENT_METHOD_SUCCESS(state, { data }) {
    SUCCESS(state, true)
    state.listUsedPaymentMethods = data
  },

  GET_LIST_USED_PAYMENT_METHOD_FAILURE(state, error) {
    FAILURE(state, error, true)
  },
  /*= =============== END AUTO REFUND ============== */
}
