import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'
import { ONBOARDING_STEP } from '@/constants'
import { getCookie } from '@/libs/common'

export default {

  // login
  LOGIN_REQUEST(state) {
    REQUEST(state)
    state.isLoggedIn = false
  },
  LOGIN_SUCCESS(state, idToken) {
    SUCCESS(state)
    state.idToken = idToken
    state.isLoggedIn = true
  },
  LOGIN_FAILURE(state, error) {
    FAILURE(state, error)
    state.isLoggedIn = false
  },

  // register
  REGISTER_REQUEST(state) {
    REQUEST(state)
  },
  REGISTER_SUCCESS(state) {
    SUCCESS(state)
  },
  REGISTER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // activate
  // ACTIVATE_REQUEST(state) {
  //   REQUEST(state)
  // },
  // ACTIVATE_SUCCESS(state) {
  //   SUCCESS(state)
  // },
  // ACTIVATE_FAILURE(state, error) {
  //   FAILURE(state, error)
  // },

  // get account info
  GET_ACCOUNT_INFO_REQUEST(state) {
    state.user.loading = true
    state.user.error = null
    state.user.status = false
  },
  GET_ACCOUNT_INFO_SUCCESS(state, user) {
    state.user.loading = false
    state.user.status = true
    state.user.data = user
    const { onboardingStep } = user

    // GA Tracking
    if ((process.env.VUE_APP_PLATFORM_NAME === 'Ecomdy') && !(user?.partnership?.exclusivePartnerId || user?.belongToPartner?.exclusivePartnerId)) {
      let phoneNumber
      if (user.contactInfo?.phone) {
        const phoneElm = user.contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          phoneNumber = `+${phoneElm[1]}${phoneElm[2]}`
        }
      }

      const source = getCookie('utm_source')
      const medium = getCookie('utm_medium')

      const dataLayer = {
        event: `user_step_${onboardingStep}`,
        user_email: user?.email,
        user_id: user?.email,
        userId: user?.email,
        user_phoneNumber: phoneNumber,
        user_step_number: onboardingStep,
        ...(source && { utm_source: source }),
        ...(medium && { utm_medium: medium }),
      }

      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)
    }
    // End GA tracking

    let isHasOnboardingStep = false
    if (onboardingStep < ONBOARDING_STEP.USING_OFFICIAL_ADS_ACCOUNT) {
      isHasOnboardingStep = true
    } else {
      isHasOnboardingStep = false
    }
    localStorage.setItem('isHasOnboardingStep', JSON.stringify(isHasOnboardingStep))

    const partnerView = localStorage.getItem('partnerView')
    if (partnerView && user?.partnership?.status !== 'active') {
      localStorage.removeItem('partnerView')
      window.location.reload()
    }
    const {
      plans,
      isInTrial,
      logs,
    } = user

    state.plansStatus.needToBeReviewed = false
    state.plansStatus.outOfTrialAndNeedPlan = false

    if (isInTrial) { // isTrialing
      state.plansStatus.isTrialing = true
    }

    // set current plan of user
    if (plans) {
      if (plans.needToBeReviewed) { // review previous cancel plan so cannot subscript new plan
        state.plansStatus.needToBeReviewed = true
        state.plansStatus[plans.type] = true
      }

      let lastStatus = ''
      if (logs && Array.isArray(logs) && logs.length > 0) {
        const lastLog = logs[logs.length - 1]
        lastStatus = lastLog.data?.subscription?.status
      }

      if (plans.subscription?.status === 'past_due' || plans.subscription?.status === 'incomplete'
        || (lastStatus === 'trialing' && plans.subscription?.status === 'canceled')) { // check user out of trial and dont't have any plan
        state.plansStatus.outOfTrialAndNeedPlan = true
      }

      if (plans.notRegisterPlans) { // can trial
        // state.plansStatus.canTrial = true
      } else if (plans.subscription?.status === 'active') { // outOfTrial and has 1 active plan
        state.plansStatus.activePlan = plans.type
      } else if (plans.subscription?.status === 'canceled') { // outOfTrial and hasn't active plan
        state.plansStatus.activePlan = null
      }
    }
  },

  GET_ACCOUNT_INFO_FAILURE(state, error) {
    state.user.loading = false
    state.user.error = error
    state.user.status = false
  },

  // get account report
  GET_ACCOUNT_REPORT_REQUEST(state) {
    state.userReports.loading = true
    state.userReports.error = null
    state.userReports.status = false
  },
  GET_ACCOUNT_REPORT_SUCCESS(state, data) {
    state.userReports.loading = false
    state.userReports.status = true
    state.userReports.data = data
  },
  GET_ACCOUNT_REPORT_FAILURE(state, error) {
    state.userReports.loading = false
    state.userReports.error = error
    state.userReports.status = false
  },

  // send link to reset
  RESET_INIT_REQUEST(state) {
    REQUEST(state)
  },
  RESET_INIT_SUCCESS(state) {
    SUCCESS(state)
  },
  RESET_INIT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // reset password
  RESET_FINISH_REQUEST(state) {
    REQUEST(state)
  },
  RESET_FINISH_SUCCESS(state) {
    SUCCESS(state)
  },
  RESET_FINISH_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get general configs
  GET_GENERAL_CONFIG_REQUEST(state) {
    REQUEST(state)
  },
  GET_GENERAL_CONFIG_SUCCESS(state, configs) {
    SUCCESS(state)
    state.generalConfigs = configs
  },
  GET_GENERAL_CONFIG_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update account
  UPDATE_ACCOUNT_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_ACCOUNT_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_ACCOUNT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // update password
  UPDATE_PASSWORD_REQUEST(state) {
    REQUEST(state)
  },
  UPDATE_PASSWORD_SUCCESS(state) {
    SUCCESS(state)
  },
  UPDATE_PASSWORD_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // verify shopify request
  VERIFY_SHOPIFY_CONNECT_REQUEST(state) {
    REQUEST(state)
  },
  VERIFY_SHOPIFY_CONNECT_SUCCESS(state, data) {
    SUCCESS(state)
    state.shopifyVerified = data
  },
  VERIFY_SHOPIFY_CONNECT_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // request verify email
  REQUEST_VERIFY_EMAIL(state) {
    REQUEST(state)
  },
  REQUEST_VERIFY_EMAIL_SUCCESS(state) {
    SUCCESS(state)
  },
  REQUEST_VERIFY_EMAIL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // TikTok app center
  REQUEST_APP_CENTER(state) {
    REQUEST(state)
  },
  REQUEST_APP_CENTER_SUCCESS(state) {
    SUCCESS(state)
  },
  REQUEST_APP_CENTER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  SEND_CODE_VERIFY_EMAIL(state) {
    REQUEST(state)
  },
  SEND_CODE_VERIFY_EMAIL_SUCCESS(state) {
    SUCCESS(state)
  },
  SEND_CODE_VERIFY_EMAIL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  VERIFY_EMAIL_BY_CODE(state) {
    REQUEST(state)
  },
  VERIFY_EMAIL_BY_CODE_SUCCESS(state, data) {
    SUCCESS(state)
    state.dataAccessToken = data
  },
  VERIFY_EMAIL_BY_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_NOTIFICATIONS_SUCCESS(state, data) {
    state.notifications = data
  },

  READ_NOTIFICATIONS(state) {
    REQUEST(state)
  },
  READ_NOTIFICATIONS_SUCCESS(state) {
    SUCCESS(state)
  },
  READ_NOTIFICATIONS_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
