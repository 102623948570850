<template>
  <div class="mobile-view-container">
    <div class="background-img" />
    <div class="content-page">
      <div class="logo-ecomdy">
        <b-img
          class="logo"
          fluid
          :src="require('@/assets/images/logo/logo-ecomdy-media.svg')"
          alt="guide"
        />
      </div>
      <div class="content-warning">
        <p>We suggest viewing the desktop version of this page</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>

<style lang="scss" scoped>
.mobile-view-container {
  width: 100%;
  height: 100vh;

  .background-img {
    background-image: url("../assets/images/pages/auth/img-slide-login-3.jpeg");
    background-position: center;
    background-repeat: no-repeat;

    width: 100%;
    height: 100%;

    position: fixed;
    left: 0;
    right: 0;
    z-index: 1;
    display: block;

    opacity: 0.3;

    @media (max-width: 760px){
      background-position-y: -11rem;
      background-position-x: -6.3rem;
    }
  }

  .content-page {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    padding: 0 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo-ecomdy {
    .logo {
      width: 200px;

      @media (max-width: 760px){
        width: 150px;
      }
    }
  }

  .content-warning {
    margin-top: 1rem;
    text-align: center;
    font-weight: 600;
    font-size: 24px;

    @media (max-width: 760px){
      font-size: 18px;
    }
  }
}
</style>
