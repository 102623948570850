// eslint-disable-next-line import/prefer-default-export
export const SUBSCRIPTION_TYPE = {
  regular_1: 'regular_1',
  regular_2: 'regular_2',
  regular_3: 'regular_3',
  regular_4: 'regular_4',
  regular_5: 'regular_5',
  enterprise: 'enterprise',
  black_agency: 'black_agency',
  white_agency: 'white_agency',
}

export const VIP_BENEFITS = [
  'No monthly fee',
  '50 TikTok Ad Account',
  'Base transaction fee: 3%',
  'Unlimited spending',
  'Unlimited setup payment method',
  'Direct support 24/7',
  'Training 1 on 1',
  'Event Sponsor',
]

export const WHITE_AGENCY_BENEFITS = [
  'Unlimited spending',
  'Unlimited setup payment method',
  'No monthly fee',
  'Base transaction fee: 3%',
]

export const REGULAR_PAYMENT_SUPPORT = [
  'PayFast',
  'Payoneer',
  'PayPal',
  'Credit card',
  'USDT',
  // 'BUSD',
  'Airwallex',
  'Wise',
  'Tazapay',
  'LianLian',
  'Local bank',
]

export const STATUS_PLAN = {
  PENDING: 'pending',
  DONE: 'done',
  REJECTED: 'rejected',
}
