import { ReferralProgramsNewService } from '@/services'
import { getResponse } from '@/plugins'

export default {
  async joinPartneroPrograms({ commit }) {
    commit('JOIN_PARTNERO_PROGRAMS_REQUEST')
    try {
      const { data } = await ReferralProgramsNewService.joinPartneroPrograms()
      commit('JOIN_PARTNERO_PROGRAMS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('JOIN_PARTNERO_PROGRAMS_FAILURE', error)
    }
  },
}
