import { DEFAULT_STATE } from '@/constants'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    listMemberContactInfo: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
  },
  getters,
  mutations,
  actions,
}
