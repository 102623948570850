import { render, staticRenderFns } from "./UpdateTaxAlert.vue?vue&type=template&id=3593d725&scoped=true&"
var script = {}
import style0 from "./UpdateTaxAlert.vue?vue&type=style&index=0&id=3593d725&lang=scss&scoped=true&"
import style1 from "./UpdateTaxAlert.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3593d725",
  null
  
)

export default component.exports