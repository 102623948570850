import i18n from '@/libs/i18n'

export default [
  {
    path: '/pixel-management',
    name: 'pixel-management',
    component: () => import('@/views/pixel-management/PixelManagement.vue'),
    meta: {
      pageTitle: i18n.t('pixel.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },

  {
    path: '/pixel-management/create',
    name: 'pixel-create',
    component: () => import('@/views/pixel-management/PixelDetail.vue'),
    meta: {
      pageTitle: i18n.t('pixel.textCreatePageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },

  {
    path: '/pixel-management/:id',
    name: 'pixel-detail',
    component: () => import('@/views/pixel-management/PixelDetail.vue'),
    meta: {
      pageTitle: i18n.t('pixel.textCreatePageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
]
