import axios from '@axios'

export default {
  track(params) {
    return axios.post('/api/referrals/click', params)
  },

  getReferralStats() {
    return axios.get('/api/referrals/rewards')
  },

  getListMemberPartner(params) {
    return axios.get('/api/partnership/members', { params })
  },

  getListMemberAds(params) {
    return axios.get(`/api/partnership/members/${params.id}/ads-accounts`, { params })
  },

  getMemberInfo(params) {
    return axios.get(`/api/partnership/members/${params.id}`, { params })
  },

  removeMemberPartner(params) {
    return axios.delete(`/api/partnership/members/${params.id}`, params)
  },

  handleProductLink(params) {
    return axios.put(`/api/partnership/members/${params.id}/product-profile`, params)
  },

  getPlanChanged(params) {
    return axios.get('/api/partnership/statistics/plan-charged', { params })
  },

  getMemberStatistic(params) {
    return axios.get('/api/partnership/statistics/members', { params })
  },

  getAdsAccount(params) {
    return axios.get('/api/partnership/statistics/ads-accounts', { params })
  },

  getDeposits(params) {
    return axios.get('/api/partnership/statistics/spending-deposit', { params })
  },
}
