import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // redeem code for member
  REDEEM_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },

  REDEEM_GIFT_CODE_SUCCESS(state) {
    SUCCESS(state)
  },

  REDEEM_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list history redeem code
  GET_LIST_HISTORY_REDEEM_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_HISTORY_REDEEM_SUCCESS(state, data) {
    SUCCESS(state)
    state.listHistoryRedeem = data
  },

  GET_LIST_HISTORY_REDEEM_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list gift code
  GET_LIST_GIFT_CODE_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_GIFT_CODE_SUCCESS(state, data) {
    SUCCESS(state)
    const currentPage = state.listGiftCode.paging.page
    if (currentPage === data.paging.page - 1) {
      state.listGiftCode.content = state.listGiftCode.content.concat(data.content)
    } else {
      state.listGiftCode.content = data.content
    }
    state.listGiftCode.paging.page = data.paging.page
    state.listGiftCode.paging.total = data.paging.total
  },

  GET_LIST_GIFT_CODE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list gift code
  GET_LIST_GIFT_CODE_PARTNER_REQUEST(state) {
    REQUEST(state)
  },

  GET_LIST_GIFT_CODE_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listGiftCodePartner = data
  },

  GET_LIST_GIFT_CODE_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get list gift code
  GET_REDEEM_HISTORY_REQUEST(state) {
    REQUEST(state)
  },

  GET_REDEEM_HISTORY_SUCCESS(state, data) {
    SUCCESS(state)
    state.listUserRedeemHistory = data
  },

  GET_REDEEM_HISTORY_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
