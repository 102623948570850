// eslint-disable-next-line import/prefer-default-export
export const ADS_ACCOUNT_STATUS = {
  SHOW_ACCOUNT_STATUS_NOT_APPROVED: 'SHOW_ACCOUNT_STATUS_NOT_APPROVED',
  SHOW_ACCOUNT_STATUS_APPROVED: 'SHOW_ACCOUNT_STATUS_APPROVED',
  SHOW_ACCOUNT_STATUS_IN_REVIEW: 'SHOW_ACCOUNT_STATUS_IN_REVIEW',
  SHOW_ACCOUNT_STATUS_PUNISHED: 'SHOW_ACCOUNT_STATUS_PUNISHED',
  CONTRACT_NOT_YET_EFFECTIVE: 'CONTRACT_NOT_YET_EFFECTIVE',
  CLOSED: 'CLOSED',
}

export const ADS_ACCOUNT_TICKETS_STATUS = {
  OPEN: 'opening',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  CLOSED: 'closed',
}

export const PARTNERSHIP_STATUS = {
  ACTIVE: 'active',
  REJECTED: 'rejected',
}

export const USER_STATUS = {
  ACTIVE: 'active',
  BLOCKED: 'blocked',
}

export const PLAN_STATUS = {
  INCOMPLETE: 'incomplete',
  TRIALING: 'trialing',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  CANCELED: 'canceled',
}

export const REGULAR_SUPSCRIPTION_CONDITION = 5000
