import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  pixels: state => state.pixel.content,
  queryPixelData: state => state.queryPixelData,
  total: state => state.pixel.paging.total,
  detail: state => state.detail,
  generatePixelData: state => state.generatePixelData,
  addNewStoreUrl: state => state.addNewStoreUrl,
  enableEmbed: state => state.enableEmbed,
  checkEnabledEmbed: state => state.checkEnabledEmbed,
  enableExtension: state => state.enableExtension,
  retrieveSettings: state => state.retrieveSettings,
  pixelLoading: state => state.loading,
}
