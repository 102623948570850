/* eslint-disable no-param-reassign */
import Vue from 'vue'

// axios
import axios from 'axios'
import router from '@/router'
import auth from './auth'

const baseURL = process.env.VUE_APP_NODE_ENV === 'production' ? window.location.origin : process.env.VUE_APP_SERVER_API
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL,
  // baseURL: 'https://bd99-116-105-76-109.ap.ngrok.io',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.getToken()}`,
    timezone: '+7',
  },
})

axiosIns.interceptors.request.use(
  config => {
    const locale = localStorage.getItem('locale') || process.env.VUE_APP_LOCALE
    if (locale) {
      const symbol = config.url.indexOf('?') > -1 ? '&' : '?'
      config.url = `${config.url}${symbol}lang=${locale}`
    }

    // Get token from localStorage
    const accessToken = auth.getToken()

    // If token is present add it to request's Authorization Header
    if (accessToken) {
    // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  error => {
    throw (error.response)
  },
)

// throw error when call aixos error
axiosIns.interceptors.response.use(null, error => {
  if ((error.response?.data?.code || error.response?.data?.messageCode) === 'error.user_was_blocked') {
    router.push('/user-block')
  }

  if ((error.response?.data?.code || error.response?.data?.messageCode) === 'error.we_are_maintaining') {
    router.push('/maintenance')
  }

  throw (error.response)
})

Vue.prototype.$http = axiosIns

export default axiosIns
