import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // Get listing
  GET_PIXELS_REQUEST(state) {
    REQUEST(state)
  },
  GET_PIXELS_SUCCESS(state, data) {
    SUCCESS(state)
    state.pixel = data
  },
  GET_PIXELS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_PIXEL_REQUEST(state) {
    REQUEST(state)
  },
  GET_PIXEL_SUCCESS(state, data) {
    state.detail = data
    state.pixelInfo = {
      name: data.pixelName,
      adsAccountId: data.adsAccountId,
    }
    SUCCESS(state)
  },
  GET_PIXEL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ATTACH_PIXEL_REQUEST(state) {
    REQUEST(state)
  },
  ATTACH_PIXEL_SUCCESS(state) {
    SUCCESS(state)
  },
  ATTACH_PIXEL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  QUERY_PIXEL_REQUEST(state) {
    state.queryPixelData.error = ''
    state.generatePixelData.error = ''
    REQUEST(state)
  },
  QUERY_PIXEL_SUCCESS(state, data) {
    state.queryPixelData = data
    SUCCESS(state)
  },
  QUERY_PIXEL_FAILURE(state, error) {
    state.queryPixelData = {
      name: '',
      adsAccountId: '',
      error: error.data.message,
    }
    FAILURE(state, error)
  },

  RESET_PIXEL(state) {
    state.detail = {}
  },
  RESET_ERROR(state) {
    state.queryPixelData.error = ''
    state.generatePixelData.error = ''
  },
  ADD_NEW_STORE_REQUEST(state) {
    REQUEST(state)
  },
  ADD_NEW_STORE_SUCCESS(state, data) {
    state.addNewStoreUrl = {
      url: data.redirectUrl,
      error: '',
    }
    SUCCESS(state)
  },
  ADD_NEW_STORE_FAILURE(state, error) {
    state.addNewStoreUrl = {
      error: error.data.message,
    }
    FAILURE(state, error)
  },
  GENERATE_PIXEL_CODE_REQUEST(state) {
    state.queryPixelData.error = ''
    state.generatePixelData.error = ''
    REQUEST(state)
  },
  GENERATE_PIXEL_CODE_SUCCESS(state, data) {
    state.generatePixelData = data
    SUCCESS(state)
  },
  GENERATE_PIXEL_CODE_FAILURE(state, error) {
    state.generatePixelData = {
      error: error.data.message,
    }
    FAILURE(state, error)
  },

  CHANGE_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  CHANGE_STATUS_SUCCESS(state) {
    SUCCESS(state)
  },
  CHANGE_STATUS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  DELETE_REQUEST(state) {
    REQUEST(state)
  },
  DELETE_SUCCESS(state) {
    SUCCESS(state)
  },
  DELETE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  RESET_NEW_STORE(state) {
    state.addNewStoreUrl = {
      url: '',
      error: '',
    }
  },

  CHECK_ENABLED_REQUEST(state, loading) {
    state.checkEnabledEmbed = {
      loading,
      enabled: state.checkEnabledEmbed.enabled,
    }
    REQUEST(state)
  },
  CHECK_ENABLED_SUCCESS(state, data) {
    state.checkEnabledEmbed = {
      loading: false,
      enabled: data.enabled,
    }
    SUCCESS(state)
  },
  CHECK_ENABLED_FAILURE(state, error) {
    state.checkEnabledEmbed = {
      loading: false,
      enabled: false,
    }
    FAILURE(state, error)
  },

  ENABLE_EXTENSION_REQUEST(state) {
    REQUEST(state)
  },
  ENABLE_EXTENSION_SUCCESS(state, data) {
    state.enableExtension = data
    state.enableExtension.error = ''
    SUCCESS(state)
  },
  ENABLE_EXTENSION_FAILURE(state, error) {
    state.enableExtension.error = error.data.message
    FAILURE(state, error)
  },
  GET_RETRIEVE_SETTINGS_REQUEST(state) {
    state.retrieveSettings = {
      ...state.retrieveSettings,
      loading: true,
    }
    REQUEST(state)
  },
  GET_RETRIEVE_SETTINGS_SUCCESS(state, data) {
    state.retrieveSettings = {
      ...data,
      loading: false,
    }
    SUCCESS(state)
  },
  GET_RETRIEVE_SETTINGS_FAILURE(state, error) {
    state.retrieveSettings = {
      ...state.retrieveSettings,
      loading: false,
    }
    FAILURE(state, error)
  },
}
