/* eslint-disable no-param-reassign */
import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get theme
  GET_PRICING_REQUEST(state) {
    REQUEST(state, true)
  },
  GET_PRICING_SUCCESS(state, plans) {
    SUCCESS(state, true)
    state.listPlan = plans || []
  },
  GET_PRICING_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  // get plan status
  GET_PLAN_STATUS_REQUEST(state) {
    REQUEST(state)
  },
  GET_PLAN_STATUS_SUCCESS(state, data) {
    SUCCESS(state, true)
    state.subscriptionStatus = data
  },
  GET_PLAN_STATUS_FAILURE(state, error) {
    FAILURE(state, error, true)
  },

  // get list transition history
  GET_TRANS_HISTORY_REQUEST(state) {
    REQUEST(state)
  },
  GET_TRANS_HISTORY_SUCCESS(state, data) {
    SUCCESS(state, true)
    state.histories = data
  },
  GET_TRANS_HISTORY_FAILURE(state, error) {
    FAILURE(state, error, true)
  },
}
