<template>
  <b-modal
    ref="ads-blocked-notice-modal"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal">
      <h4 class="font-medium text-primary">
        Announcement
      </h4>
      <div>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div
      class="notice-container"
    >
      <div class="notice-content">
        <div class="d-flex align-items-center mb-1">
          <b-img
            :src="require('@/assets/images/pages/overview/ic-warning.svg')"
            alt="logo"
          />
          <b-card-title class="text-title">
            [ECOMDY] Official Announcement about Ecomdy System issue on October 14, 2022
          </b-card-title>
        </div>
        <div>
          <b-card-text>
            Dear customers ...
            <a
              class="text-blue underline"
              target="_blank"
              href="https://ecomdycom.larksuite.com/docx/doxuss0uZGKoWbc9qTDxMpfbQkT"
            >
              Read more
            </a>
          </b-card-text>
        </div>
      </div>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        @click="hideModal"
      >
        Got It
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>
import {
  BModal, BButton, BCardTitle, BCardText, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
    BCardText,
    BCardTitle,
    BImg,
  },

  data() {
    return {
      // validation
    }
  },

  computed: {

  },

  methods: {
    hideModal() {
      this.$refs['ads-blocked-notice-modal'].hide()
    },

    showModal() {
      this.$refs['ads-blocked-notice-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.notice-container{
  margin: 24px 0;

  .notice-content{
    max-width: 709px;
    width: 100%;

    background-color: #E11C4E1A;
    border-radius: 20px;

    padding: 16px;
    margin: 0 auto;

    .text-title{
      margin:0 0 0 15px;

      font-size: 22px;
    }
  }
}
</style>
