import axios from '@axios'

export default {
  getPixels(params) {
    return axios.get('/api/pixels', { params })
  },

  getPixel(params) {
    return axios.get(`/api/pixels/${params}`)
  },

  queryPixel(params) {
    return axios.post('/api/pixels/name', params)
  },

  attachPixel(params) {
    return axios.post('/api/pixels/attach-store', params)
  },

  addNewStore(params) {
    return axios.get(`/api/shopify/connect/request?shop=${params.shopUrl}&pixelCode=${params.pixelCode}&pixelName=${params.pixelName}&pixelAdsAccountId=${params.adsAccountId}`)
  },

  changeStatus(params) {
    return axios.put(`/api/pixels/${params.id}`, params.payload)
  },

  deletePixel(params) {
    return axios.delete(`/api/pixels/${params}`)
  },
  generatePixel(params) {
    return axios.post('/api/pixels/generate', params)
  },

  checkEnableEmbedStore(params) {
    return axios.get(`/api/shopify/theme-embed-status?shopUrl=${params.shopUrl}`)
  },

  enabledExtension(params) {
    return axios.get(`/api/shopify/enable-extension?shopUrl=${params.shopUrl}`)
  },

  getRetrieveSetting(params) {
    return axios.get(`/api/pixels/${params}/settings`)
  },
}
