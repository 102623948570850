/* eslint-disable global-require */
import { orderBy } from 'lodash'
import { DEFAULT_GETTERS } from '@/constants'
import i18n from '@/libs/i18n'

export default {
  ...DEFAULT_GETTERS,
  stats: state => state.stats,
  listMemberPartner: state => state.listMemberPartner,
  memberAds: state => state.memberAds,
  memberDetail: state => state.memberDetail,
  listMemberPartnerActivated: state => state.listMemberPartnerActivated,
  listMemberPartnerBlocked: state => state.listMemberPartnerBlocked,
  listProductLinks: state => state.listProductLinks,
  planChange: state => state.planChange,
  memberStatistic: state => state.memberStatistic,
  deposits: state => state.deposits,

  blockedUsers: state => {
    const blockedUsers = state.user.statisticsUser.filter(user => user.status === 'blocked')
    const blockedUsersSort = orderBy(blockedUsers, [state.typeSort], ['asc'])
    const customUser = []
    if (state.typeSort === 'day') {
      for (let index = 1; index <= state.limitDay; index += 1) {
        const element = blockedUsersSort.find(item => item.day === index)
        if (!element) {
          customUser.push({
            status: 'blocked',
            day: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            day: element.day,
            total: element.total,
          })
        }
      }
    } else {
      for (let index = 1; index <= state.limitMonth; index += 1) {
        const element = blockedUsersSort.find(item => item.month === index)
        if (!element) {
          customUser.push({
            status: 'blocked',
            month: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            month: element.month,
            total: element.total,
          })
        }
      }
    }
    return customUser.map(item => item.total || 0)
  },

  activedUsers: state => {
    const activedUsers = state.user.statisticsUser.filter(user => user.status === 'active')
    const activedUsersSort = orderBy(activedUsers, [state.typeSort], ['asc'])
    const customUser = []
    if (state.typeSort === 'day') {
      for (let index = 1; index <= state.limitDay; index += 1) {
        const element = activedUsersSort.find(item => item.day === index)
        if (!element) {
          customUser.push({
            status: 'active',
            day: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            day: element.day,
            total: element.total,
          })
        }
      }
    } else {
      for (let index = 1; index <= state.limitMonth; index += 1) {
        const element = activedUsersSort.find(item => item.month === index)
        if (!element) {
          customUser.push({
            status: 'active',
            month: index,
            total: 0,
          })
        } else {
          customUser.push({
            status: element.status,
            month: element.month,
            total: element.total,
          })
        }
      }
    }
    return customUser.map(item => item.total || 0)
  },

  typeSort: state => state.typeSort,
  typeSortDeposit: state => state.typeSortDeposit,

  monthlyAnalytics: state => ([
    {
      label: i18n.t('partnerView.textTotalMember'),
      value: state.user.totalUsers !== null ? state.user.totalUsers : 'N/A',
      icon: require('@/assets/images/pages/referral/ic-members.svg'),
      des: i18n.t('partnerView.textTotalMember'),
    },
    {
      label: i18n.t('home.summaryOverview.titleTotalAdsAccount'),
      value: state.adsAccounts.totalAdsAccounts !== null ? state.adsAccounts.totalAdsAccounts : 'N/A',
      icon: require('@/assets/images/pages/referral/ic-ads-account.svg'),
      des: i18n.t('partnerView.textTotalAdAccountOfMember'),
    },
    {
      label: i18n.t('partnerView.titleTotalSpending'),
      value: `$${Math.floor((state.deposits.totalSpending) * 100) / 100}`,
      icon: require('@/assets/images/pages/referral/ic-deposit.svg'),
      des: i18n.t('partnerView.desTotalSpending'),
    },
  ]),

}
