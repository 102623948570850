import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // upload file
  UPLOAD_FILE_REQUEST(state) {
    REQUEST(state)
  },
  UPLOAD_FILE_SUCCESS(state, url) {
    SUCCESS(state)
    state.fileUrl = url
  },
  UPLOAD_FILE_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // upload video
  UPLOAD_VIDEO_REQUEST(state) {
    REQUEST(state)
  },

  UPLOAD_VIDEO_SUCCESS(state, url) {
    SUCCESS(state)
    state.fileVideo = url
  },
  UPLOAD_VIDEO_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
