import i18n from '@/libs/i18n'

export default [
  {
    path: '/partner-management',
    name: 'partner-management',
    component: () => import('@/views/partner-management/dashboard/Dashboard.vue'),
    meta: {
      pageTitle: i18n.t('referralProgram.dashboardPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
  {
    path: '/partner-management-member',
    name: 'partner-management-member',
    component: () => import('@/views/partner-management/member-management/MemberManagement.vue'),
    meta: {
      pageTitle: i18n.t('referralProgram.accountManagementPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
  {
    path: '/member/:id',
    name: 'member-detail',
    component: () => import('@/views/partner-management/member-detail/MemberDetail.vue'),
    meta: {
      pageTitle: i18n.t('partnerView.titleMemberAdsManagement'),
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
  {
    path: '/partner-management-product-link',
    name: 'partner-management-product-link',
    component: () => import('@/views/partner-management/member-product-link/MemberProductLink.vue'),
    meta: {
      pageTitle: i18n.t('referralProgram.memberProductLinksTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
  {
    path: '/partner-management-giftcode',
    name: 'partner-management-giftcode',
    component: () => import('@/views/partner-management/gift-code-management/TableGiftCode.vue'),
    meta: {
      pageTitle: i18n.t('referralProgram.giftCodeManagementTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },

  {
    path: '/partner-management-contact-info',
    name: 'partner-management-contact-info',
    component: () => import('@/views/partner-management/member-verify-information/MemberContactInfoTable.vue'),
    meta: {
      pageTitle: i18n.t('partner.textPageMemberContactInfoTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },

  {
    path: '/partner-info-url',
    name: 'partner-info-url',
    component: () => import('@/views/partner-management/embed-form-info-URL/FormInfoURLView.vue'),
    meta: {
      pageTitle: i18n.t('partner.partnerInfoURLTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
    },
  },
]
