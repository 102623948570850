import { BillingService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async getPricing({ commit }) {
    commit('GET_PRICING_REQUEST')
    try {
      const { data } = await BillingService.getPricing()
      commit('GET_PRICING_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_PRICING_FAILURE', error)
    }
  },

  async getSubscriptionStatus({ commit }) {
    commit('GET_PLAN_STATUS_REQUEST')
    try {
      const { data } = await BillingService.getSubscriptionStatus()
      commit('GET_PLAN_STATUS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_PLAN_STATUS_FAILURE', error)
    }
  },

  async getTransitionHistory({ commit }, params) {
    commit('GET_TRANS_HISTORY_REQUEST')
    try {
      const { data } = await BillingService.getTransitionHistory(params)
      commit('GET_TRANS_HISTORY_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_TRANS_HISTORY_FAILURE', error)
    }
  },
}
