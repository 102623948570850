// eslint-disable-next-line import/prefer-default-export
export const OPTIONS_BUSINESS_TYPE = [
  'Services',
  'Food/Beverage: Primariy e-commerce and shipping',
  'Food/Beverage: Primariy restaurant/Cafe/Food truck',
  'Toys/Crafts/Hobbies',
  'Automotive',
  'Apparel/Shoes/Accessories',
  'Jewelry',
  'Streaming/Online classes/Software license keys',
  'Subscription-based products or services',
  'Tobacco/Vape/Smoking/Marijuana related products',
  'Adult',
  'Home/Furniture',
  'Health/Beauty',
  'Hardware/Garden/Home Improvement',
  'Art/Photography',
  'Pet Care',
  'Electronics/Computers',
  'Books/Music/Video',
  'Office Supplies',
  'Sports/Fitness/Outdoors',
  'Other',
]

export const OPTIONS_BUSINESS_MODEL = [
  'Dropshipping',
  'POD (Print On Demand)',
  'Agency',
  'Branding',
  'Other',
]

export const OPTIONS_HAS_RUN_TIK_TOK = [
  {
    label: 'Yes',
    value: true,
  },
  {
    label: 'No',
    value: false,
  },
]

export const OPTIONS_PLATFORM_STORE = [
  'Shopify',
  'WooCommerce',
  'ShopBase',
  'BigCommerce',
  'Magento Commerce',
  'Squarespace',
  'Volusion',
  'Wix',
  'Other',
]

export const OPTIONS_MONTHLY_REVENUE = [
  '<$1000',
  '$1000 - $5000',
  '$5000 - $20000',
  '$20000 - $50000',
  '$50000 - $150000',
  '$150000 - $250000',
  '> $250000',
]

export const PROFILE_STATUS = {
  APPROVED: 'approved',
  REVIEWING: 'reviewing',
  REJECTED: 'rejected',
  NOT_FOUND: 'not_found',
}
