import axios from '@axios'

export default {
  redeemGiftCode(params) {
    return axios.post('/api/users/gift-codes/redeem', params)
  },
  getListHistoryRedeem(params) {
    return axios.get('/api/transactions?types[]=recharge_gift_code', { params })
  },
  getListGiftCode(params) {
    return axios.get(`/api/partnership/${params.id}/gift-codes?unexpired=true`, { params })
  },
  getListGiftCodePartner(params) {
    const { id, ...realParams } = params
    return axios.get(`/api/partnership/${id}/gift-codes`, { params: realParams })
  },

  getRedeemHistory(params) {
    return axios.get('/api/users/gift-codes/redeem-history', { params })
  },
}
