import i18n from '@/libs/i18n'

export default [
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/billing/Billing.vue'),
    meta: {
      pageTitle: i18n.t('layout.billingDetail'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },
]
