import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  FETCH_LIST_MEMBER_INFO_REQUEST(state) {
    REQUEST(state)
  },

  FETCH_LIST_MEMBER_INFO_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMemberContactInfo = data
  },

  FETCH_LIST_MEMBER_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // verify contact information
  VERIFY_CONTACT_INFO_REQUEST(state) {
    REQUEST(state)
  },

  VERIFY_CONTACT_INFO_SUCCESS(state) {
    SUCCESS(state)
  },

  VERIFY_CONTACT_INFO_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // create product link for member
  CREATE_PRODUCT_LINK_FOR_MEMBER_REQUEST(state) {
    REQUEST(state)
  },

  CREATE_PRODUCT_LINK_FOR_MEMBER_SUCCESS(state) {
    SUCCESS(state)
  },

  CREATE_PRODUCT_LINK_FOR_MEMBER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  ADREACH_SURVEY_REQUEST(state) {
    REQUEST(state)
  },

  ADREACH_SURVEY_SUCCESS(state) {
    SUCCESS(state)
  },

  ADREACH_SURVEY_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
