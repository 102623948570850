import axios from '@axios'

export default {
  getPricing() {
    return axios.get('/api/stripe-payment/products/prices')
  },

  getValidPricing() {
    return axios.get('/api/stripe-payment/products/valid-prices')
  },

  getListCard() {
    return axios.get('/stripe-payments/payment-methods')
  },

  getSubscriptionStatus() {
    return axios.get('/api/stripe-payment/subscriptions/my')
  },

  getTransitionHistory(params) {
    return axios.get('/api/transactions', { params })
  },
}
