import { REQUEST, SUCCESS, FAILURE } from '@/plugins/store'

export default {
  // get general configs
  CREATE_PRODUCT_LINK_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_PRODUCT_LINK_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_PRODUCT_LINK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  CREATE_PRODUCT_VIDEO_REQUEST(state) {
    REQUEST(state)
  },
  CREATE_PRODUCT_VIDEO_SUCCESS(state) {
    SUCCESS(state)
  },
  CREATE_PRODUCT_VIDEO_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
