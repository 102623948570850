import axios from '@axios'

export default {
  uploadFile(file) {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(`${process.env.VUE_APP_UPLOAD_SERVER_API}${process.env.VUE_APP_UPLOAD_PATH}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  uploadVideo(file, { onUploadProgress }) {
    const formData = new FormData()
    formData.append('file', file)
    return axios.post(`${process.env.VUE_APP_UPLOAD_SERVER_API}/api/upload/video`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },
}
