import { GiftCode } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async redeemGiftCode({ commit }, params) {
    commit('REDEEM_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCode.redeemGiftCode(params)
      commit('REDEEM_GIFT_CODE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('REDEEM_GIFT_CODE_FAILURE', error)
    }
  },

  async getListHistoryRedeem({ commit }, params) {
    commit('GET_LIST_HISTORY_REDEEM_REQUEST')
    try {
      const { data } = await GiftCode.getListHistoryRedeem(params)
      commit('GET_LIST_HISTORY_REDEEM_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_HISTORY_REDEEM_FAILURE', error)
    }
  },

  async getListGiftCode({ commit }, params) {
    commit('GET_LIST_GIFT_CODE_REQUEST')
    try {
      const { data } = await GiftCode.getListGiftCode(params)
      commit('GET_LIST_GIFT_CODE_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_GIFT_CODE_FAILURE', error)
    }
  },

  async getListGiftCodePartner({ commit }, params) {
    commit('GET_LIST_GIFT_CODE_PARTNER_REQUEST')
    try {
      const { data } = await GiftCode.getListGiftCodePartner(params)
      commit('GET_LIST_GIFT_CODE_PARTNER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_LIST_GIFT_CODE_PARTNER_FAILURE', error)
    }
  },

  async getRedeemHistory({ commit }, params) {
    commit('GET_REDEEM_HISTORY_REQUEST')
    try {
      const { data } = await GiftCode.getRedeemHistory(params)
      commit('GET_REDEEM_HISTORY_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('GET_REDEEM_HISTORY_FAILURE', error)
    }
  },
}
