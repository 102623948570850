import {
  REQUEST, SUCCESS, FAILURE,
} from '@/plugins/store'

export default {
  JOIN_PARTNERO_PROGRAMS_REQUEST(state) {
    REQUEST(state)
  },
  JOIN_PARTNERO_PROGRAMS_SUCCESS(state, data) {
    SUCCESS(state)
    state.accountPartnero = data
  },
  JOIN_PARTNERO_PROGRAMS_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
