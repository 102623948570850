import axios from '@axios'

export default {
  connectAccount(params) {
    return axios.post('/api/billings/payoneer/connect', params)
  },

  getBalances() {
    return axios.get('/api/billings/payoneer/balances')
  },

  requestPayment(params) {
    return axios.post('/api/payoneer-payment/request-payment', params)
  },

  confirmPayment(params) {
    const { transactionId } = params
    return axios.post(`/api/payoneer-payment/transactions/${transactionId}/confirm`)
  },

  disconnectAccount() {
    return axios.post('/api/billings/payoneer/disconnect')
  },

  getConnectCallback({ params }) {
    const { code, scope, state } = params
    return axios.get(`/api/billings/payoneer/connect-callback?code=${code}&scope=${scope}&state=${state}`)
  },

  getChallengeCallback({ params }) {
    const {
      transactionId, type, session_id, response_path,
    } = params
    // eslint-disable-next-line camelcase
    return axios.get(`/api/payoneer-payment/transactions/${transactionId}/challenge/callback?type=${type}&session_id=${session_id}&response_path=${response_path}`)
  },
}
