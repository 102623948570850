import { PartnerShipService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {

  async fetchListMemberInformation({ commit }, params) {
    commit('FETCH_LIST_MEMBER_INFO_REQUEST')
    try {
      const { data } = await PartnerShipService.fetchListMemberInformation(params)
      commit('FETCH_LIST_MEMBER_INFO_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('FETCH_LIST_MEMBER_INFO_FAILURE', error)
    }
  },

  async verifyContactInformation({ commit }, params) {
    commit('VERIFY_CONTACT_INFO_REQUEST')
    try {
      const { data } = await PartnerShipService.verifyContactInformation(params)
      commit('VERIFY_CONTACT_INFO_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('VERIFY_CONTACT_INFO_FAILURE', error)
    }
  },

  // createProductLinkForMember
  async createProductLinkForMember({ commit }, params) {
    commit('CREATE_PRODUCT_LINK_FOR_MEMBER_REQUEST')
    try {
      const { data } = await PartnerShipService.createProductLinkForMember(params)
      commit('CREATE_PRODUCT_LINK_FOR_MEMBER_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('CREATE_PRODUCT_LINK_FOR_MEMBER_FAILURE', error)
    }
  },

  async adreachSurvey({ commit }, params) {
    commit('ADREACH_SURVEY_REQUEST')
    try {
      const { data } = await PartnerShipService.adreachSurvey(params)
      commit('ADREACH_SURVEY_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('ADREACH_SURVEY_FAILURE', error)
    }
  },
}
