import i18n from '@/libs/i18n'

export default [
  {
    path: '/my-ads-account',
    name: 'my-ads-account',
    component: () => import('@/views/my-ads-account/Index.vue'),
    meta: {
      pageTitle: i18n.t('account.textPageTitle'),
      requireAuth: true,
      requireUpdateInfo: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/my-ads-account/failed-connect',
    name: 'ads-account',
    component: () => import('@/views/my-ads-account/Failed.vue'),
    meta: {
      pageTitle: i18n.t('account.textPageTitle'),
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/demo-ads-account',
    name: 'demo-ads-account',
    component: () => import('@/views/my-ads-account/components/DemoAdsAccount.vue'),
    meta: {
      pageTitle: 'Demo Ad Account',
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/reactive',
    name: 'reactive',
    component: () => import('@/views/my-ads-account/Reactive.vue'),
    meta: {
      layout: 'full',
    },
  },
]
