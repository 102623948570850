import i18n from '@/libs/i18n'

export default [
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/payment/Payment.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      requireUpdateInfo: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/payment/add-fund',
    name: 'add-fund',
    component: () => import('@/views/payment/AddFund.vue'),
    meta: {
      pageTitle: i18n.t('stepGuideControl.textAddFund'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      requireUpdateInfo: true,
      // requirePlan: true,
    },
  },
  {
    path: '/payment/success',
    name: 'add-fund-success',
    component: () => import('@/views/payment/Success.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },
  {
    path: '/payment/failed',
    name: 'add-fund-failed',
    component: () => import('@/views/payment/Failed.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },

  {
    path: '/payment/tazapay/success',
    name: 'tazapay-payments-success',
    component: () => import('@/views/payment/components/Tazapay/Success.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },
  {
    path: '/payment/tazapay/progress',
    name: 'tazapay-payments-progress',
    component: () => import('@/views/payment/components/Tazapay/Progress.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },
  {
    path: '/payment/tazapay/error',
    name: 'tazapay-payments-error',
    component: () => import('@/views/payment/components/Tazapay/Error.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },

  // lianlian payment
  {
    path: '/payment/lianlian/progress',
    name: 'lianlian-progress',
    component: () => import('@/views/payment/components/LianLian/Progress.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },

  {
    path: '/payment/lianlian/success',
    name: 'tazapay-success',
    component: () => import('@/views/payment/components/LianLian/Success.vue'),
    meta: {
      pageTitle: i18n.t('payment.textPageTitle'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
      // requirePlan: true,
    },
  },
]
