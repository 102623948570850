import {
  REQUEST, SUCCESS, FAILURE, getResponse,
} from '@/plugins/store'

export default {
  GET_STATS_REQUEST(state) {
    REQUEST(state)
  },
  GET_STATS_SUCCESS(state, data) {
    SUCCESS(state)
    state.stats = getResponse(data)
  },
  GET_STATS_FAILURE(state, error) {
    FAILURE(state, error)
  },
  TRACK_REQUEST(state) {
    REQUEST(state)
  },
  TRACK_SUCCESS(state) {
    SUCCESS(state)
  },
  TRACK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get member management list
  GET_MEMBER_PARTNER_REQUEST(state) {
    REQUEST(state)
  },

  GET_MEMBER_PARTNER_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMemberPartner = data
  },

  GET_MEMBER_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get member management list activated
  GET_MEMBER_PARTNER_ACTIVATED_REQUEST(state) {
    REQUEST(state)
  },
  GET_MEMBER_PARTNER_ACTIVATED_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMemberPartnerActivated = data
  },
  GET_MEMBER_PARTNER_ACTIVATED_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get member management list blocked
  GET_MEMBER_PARTNER_BLOCKED_REQUEST(state) {
    REQUEST(state)
  },
  GET_MEMBER_PARTNER_BLOCKED_SUCCESS(state, data) {
    SUCCESS(state)
    state.listMemberPartnerBlocked = data
  },
  GET_MEMBER_PARTNER_BLOCKED_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get member ads list
  GET_LIST_MEMBER_ADS_REQUEST(state) {
    REQUEST(state)
  },
  GET_LIST_MEMBER_ADS_SUCCESS(state, data) {
    SUCCESS(state)
    state.memberAds = data
  },
  GET_LIST_MEMBER_ADS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get info member list
  GET_MEMBER_DETAIL_REQUEST(state) {
    REQUEST(state)
  },
  GET_MEMBER_DETAIL_SUCCESS(state, data) {
    SUCCESS(state)
    state.memberDetail = data
  },
  GET_MEMBER_DETAIL_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // remove member partner list
  REMOVE_MEMBER_PARTNER_REQUEST(state) {
    REQUEST(state)
  },
  REMOVE_MEMBER_PARTNER_SUCCESS(state) {
    SUCCESS(state)
  },
  REMOVE_MEMBER_PARTNER_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // remove member partner list
  HANDLE_PRODUCT_LINK_REQUEST(state) {
    REQUEST(state)
  },

  HANDLE_PRODUCT_LINK_SUCCESS(state) {
    SUCCESS(state)
  },

  HANDLE_PRODUCT_LINK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get member product link
  GET_MEMBER_PRODUCT_LINK_REQUEST(state) {
    REQUEST(state)
  },

  GET_MEMBER_PRODUCT_LINK_SUCCESS(state, data) {
    SUCCESS(state)
    state.listProductLinks = data
  },

  GET_MEMBER_PRODUCT_LINK_FAILURE(state, error) {
    FAILURE(state, error)
  },

  // get plan change
  GET_PLAN_CHANGED_REQUEST(state) {
    REQUEST(state)
  },

  GET_PLAN_CHANGED_SUCCESS(state, data) {
    SUCCESS(state)
    state.planChange = data
  },

  GET_PLAN_CHANGED_FAILURE(state, error) {
    FAILURE(state, error)
  },

  SET_TYPE_USER_SORT(state, type) {
    state.typeSort = type
  },
  SET_LIMIT_DAY_USER(state, limit) {
    state.limitDay = limit
  },
  SET_LIMIT_MONTH_USER(state, limit) {
    state.limitMonth = limit
  },

  GET_USER_ANALYTICS_REQUEST(state) {
    REQUEST(state)
  },
  GET_USER_ANALYTICS_SUCCESS(state, data) {
    SUCCESS(state)
    state.user = data
  },
  GET_USER_ANALYTICS_FAILURE(state, error) {
    FAILURE(state, error)
    state.user.totalUsers = null
  },

  GET_USERS_REQUEST(state) {
    REQUEST(state)
  },
  GET_USERS_SUCCESS(state, data) {
    SUCCESS(state)
    state.users = data
  },
  GET_USERS_FAILURE(state, error) {
    FAILURE(state, error)
  },

  GET_ADS_ACCOUNTS_REQUEST(state) {
    REQUEST(state)
  },
  GET_ADS_ACCOUNTS_SUCCESS(state, data) {
    SUCCESS(state)
    state.adsAccounts = data
  },
  GET_ADS_ACCOUNTS_FAILURE(state, error) {
    FAILURE(state, error)
    state.adsAccounts.totalAdsAccounts = null
  },

  GET_DEPOSITS_REQUEST(state) {
    REQUEST(state)
  },
  GET_DEPOSITS_SUCCESS(state, data) {
    SUCCESS(state)
    state.deposits = data
  },
  GET_DEPOSITS_FAILURE(state, error) {
    FAILURE(state, error)
  },
}
