// import i18n from '@/libs/i18n'

import i18n from '@/libs/i18n'

export default [
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('@/views/pricing/Pricing.vue'),
    meta: {
      pageTitle: i18n.t('pricingPage.titlePricingPlan'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
    },
  },
]
