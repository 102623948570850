import { AdsAccountService, AuthService } from '@/services'
import { getResponse } from '@/plugins/store'

export default {

  async getAccountList({ commit }, params) {
    commit('GET_ACCOUNTS_REQUEST')
    try {
      const { data } = await AdsAccountService.getListAccount(params)
      commit('GET_ACCOUNTS_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ACCOUNTS_FAILURE', error)
    }
  },

  async getTicketList({ commit }, params) {
    commit('GET_TICKET_REQUEST')
    try {
      const { data } = await AdsAccountService.getListTicket(params)
      commit('GET_TICKET_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_TICKET_FAILURE', error)
    }
  },

  async createAdsAccount({ commit }, params) {
    commit('CREATE_ADS_ACCOUNT_REQUEST')
    try {
      await AdsAccountService.createAdsAccount(params)
      commit('CREATE_ADS_ACCOUNT_SUCCESS')
    } catch (error) {
      commit('CREATE_ADS_ACCOUNT_FAILURE', error)
    }
  },

  // add ad account new
  async createAdsAccountNew({ commit }, params) {
    commit('CREATE_ADS_ACCOUNT_REQUEST')
    try {
      await AdsAccountService.createAdsAccountNew(params)
      commit('CREATE_ADS_ACCOUNT_SUCCESS')
    } catch (error) {
      commit('CREATE_ADS_ACCOUNT_FAILURE', error)
    }
  },

  async getBusinessAccount({ commit }, params) {
    commit('GET_BUSINESS_ACCOUNT_REQUEST')
    try {
      const { data } = await AdsAccountService.getBusinessAccount(params)
      commit('GET_BUSINESS_ACCOUNT_SUCCESS', getResponse(data))
      commit('SET_BUSINESS_ACCOUNT_MESSAGE', '')
    } catch (error) {
      if (error.data.code === 'error.user_not_grant_tiktok_permission_to_system') {
        await AuthService.getAccountInfo()
        commit('SET_BUSINESS_ACCOUNT_MESSAGE', error.data.message)
      }
      commit('GET_BUSINESS_ACCOUNT_FAILURE', error)
    }
  },

  async connectTiktok({ commit }) {
    commit('CONNECT_TIKTOK_REQUEST')
    try {
      const { data } = await AdsAccountService.connectTiktok()

      commit('CONNECT_TIKTOK_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CONNECT_TIKTOK_FAILURE', error)
    }
  },

  async createBusinessAccount({ commit }, params) {
    commit('CREATE_BUSINESS_ACCOUNT_REQUEST')
    try {
      const { data } = await AdsAccountService.createBusinessAccount(params)

      commit('CREATE_BUSINESS_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('CREATE_BUSINESS_ACCOUNT_FAILURE', error)
    }
  },

  async getAdsAccount({ commit }, params) {
    commit('GET_ADS_ACCOUNT_REQUEST')
    try {
      const { data } = await AdsAccountService.listAdsAccount(params)
      commit('GET_ADS_ACCOUNT_SUCCESS', getResponse(data))
    } catch (error) {
      commit('GET_ADS_ACCOUNT_FAILURE', error)
    }
  },
}
