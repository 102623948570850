import axios from '@axios'

export default {
  fetchListMemberInformation(params) {
    return axios.get('/api/partnership/members/contact-info', { params })
  },

  // verify contact information user
  verifyContactInformation(params) {
    const { id, status, ...realParams } = params
    return axios.post(`/api/partnership/members/${id}/contact-info/${status}`, realParams)
  },

  // partner add product link for member
  createProductLinkForMember(params) {
    return axios.put(`/api/partnership/members/${params.id}/product-profile`, params.payload)
  },

  adreachSurvey(params) {
    return axios.post('/api/exclusive-partners/surveys', params)
  },
}
