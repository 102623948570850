import { NAME_TOKEN } from '@/constants'

export default {
  getToken() {
    return localStorage.getItem(NAME_TOKEN) || ''
  },
  setToken(token) {
    localStorage.setItem(NAME_TOKEN, token)
  },

  clearToken() {
    localStorage.removeItem(NAME_TOKEN)
  },

  clearLocalStorage() {
    localStorage.clear()
  },

  getCookie(name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  },
}
