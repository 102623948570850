import i18n from '@/libs/i18n'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import('@/views/auth/VerifyEmail.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/verify-email-by-code',
    name: 'verify-email-by-code',
    component: () => import('@/views/auth/VerifyEmailByCode.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/verify-email-fail',
    name: 'verify-email-fail',
    component: () => import('@/views/auth/VerifyEmailFail.vue'),
    meta: {
      layout: 'full',
      requireNotAuth: true,
    },
  },
  {
    path: '/user-block',
    name: 'user-block',
    component: () => import('@/views/auth/UserBlock.vue'),
    meta: {
      layout: 'full',
      requireAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: {
      pageTitle: i18n.t('layout.textProfile'),
      layout: 'vertical',
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/product-link',
    name: 'product-link',
    component: () => import('@/views/product-links/ProductLink.vue'),
    meta: {
      pageTitle: i18n.t('productLinkPage.titlePage'),
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/add-product-link',
    name: 'add-product-link',
    component: () => import('@/views/product-links/components/AddProductLink.vue'),
    meta: {
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/update-information',
    name: 'update-information-business',
    component: () => import('@/views/auth/Introduce/PrimaryContact.vue'),
    meta: {
      pageTitle: i18n.t('stepGuideControl.titleStep2'),
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/waiting-verify-information',
    name: 'waiting-verify-information',
    component: () => import('@/views/auth/Introduce/SubmitInfoSuccess.vue'),
    meta: {
      pageTitle: i18n.t('stepGuideControl.titleWaitingVerifyContactInfo'),
      requireAuth: true,
      hasGrayBg: true,
    },
  },
  {
    path: '/shopify/connect',
    name: 'shopify-connect',
    component: () => import('@/views/auth/ShopifyConnect.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reactive-stripe',
    name: 'reactive-stripe',
    component: () => import('@/views/auth/ReactiveStripe.vue'),
    meta: {
      requireAuth: true,
      hasGrayBg: true,
    },
  },
]
