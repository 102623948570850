import i18n from '@/libs/i18n'

export default [
  {
    path: '/',
    name: 'dashboard',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      pageTitle: i18n.t('layout.textOverview'),
      requireAuth: true,
      requireUpdateInfo: true,
      hasGrayBg: true,
    },
  },
]
