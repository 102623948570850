export const METAP_MARKETS = {
  url: [
    'https://calendly.com/tuyphong_ecomdy/15min',
    'https://calendly.com/nana_ecomdy/15min',
    'https://calendly.com/helenecomdy/bookmeeting',
    'https://calendly.com/maikathy94/30min',
  ],
  countries: [
    'Egypt',
    'Saudi Arabia',
    'Turkey',
    'United Arab Emirates',
    'South Africa',
    'Pakistan',
    'Morocco',
    'Kuwait',
    'Qatar',
    'Bahrain',
    'Jordan',
    'Lebanon',
    'Iraq',
  ],
}

export const EU_MARKETS = {
  url: [
    'https://calendly.com/tiff_ecomdy/kyc-uce-tiff',
    'https://calendly.com/esther_ecomdy/kyc',
    'https://calendly.com/tina_ecomdy/kyc',
    'https://calendly.com/cadie_ecomdy/know-your-customer',
  ],
  countries: [
    'United States',
    'Austria',
    'Belgium',
    'Bulgaria',
    'Croatia',
    'Czech Republic',
    'Denmark',
    'Estonia',
    'Finland',
    'France',
    'Germany',
    'Greece',
    'Hungary',
    'Ireland',
    'Italy',
    'Latvia',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Netherlands',
    'Poland',
    'Portugal',
    'Romania',
    'Slovakia',
    'Slovenia',
    'Spain',
    'Canada',
    'Sweden',
    'Slovak Republic',
    'Israel',
    'Norway',
    'Greece',
    'United Kingdom',
    'Switzerland',
  ],
}

export const AU_MARKETS = {
  url: [
    'https://calendly.com/johnny_tran/30min',
    'https://calendly.com/dora_ecomdy/30min',
    'https://calendly.com/rosynguyen/30min',
    'https://calendly.com/ram_ecomdy/30min',
    'https://calendly.com/nhung_ecomdy/ecomdy_nhung_meeting',
    'https://calendly.com/amy_ecomdy/kyc',
  ],
  countries: [
    'Australia',
    'New Zealand',
    'Brazil',
    'Argentina',
    'Chile',
    'Colombia',
    'Peru',
    'Mexico',
  ],
}

export const ASIAN_MARKETS = {
  url: [
    'https://calendly.com/giangecomdy/know-your-customer',
    'https://calendly.com/trang-ecomdy/know-your-customer',
  ],
  countries: [
    'Russian Federation',
    'China',
    'India',
    'Kazakhstan',
    'Saudi Arabia',
    'Iran, Islamic Republic of',
    'Mongolia',
    'Indonesia',
    'Myanmar',
    'Afghanistan',
    'Yemen',
    'Thailand',
    'Turkmenista',
    'Uzbekistan',
    'Japan',
    'Vietnam',
    'Malaysia',
    'Oman',
    'Philippines',
    'Laos',
    'Kyrgyzstan',
    'Syrian Arab Republic',
    'Cambodia',
    'Bangladesh',
    'Nepal',
    'Tajikistan',
    'Korea, Democratic People\'s Republic of',
    'Korea, Republic of',
    'Azerbaijan',
    'Georgia',
    'Sri Lanka',
    'Bhutan',
    'Taiwan',
    'Armenia',
    'Israel',
    'Timor-Leste',
    'Cyprus',
    'Palestine, State of',
    'Brunei Darussalam',
    'Bahrain',
    'Singapore',
    'Maldives',
    'Japan',
  ],
}
