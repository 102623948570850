import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    stats: [],

    listMemberPartner: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },

    listMemberPartnerActivated: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },

    listMemberPartnerBlocked: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },

    memberAds: [],
    memberDetail: {},
    planChange: {
      planCharged: [],
      totalAmount: 0,
      totalUser: 0,
    },

    memberStatistic: {
      planCharged: [],
      totalAmount: 0,
      totalUser: 0,
    },

    listProductLinks: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },

    users: {
      statisticsUser: [],
      totalUsers: 0,
    },

    user: {
      statisticsUser: [],
      totalUsers: 0,
    },

    typeSort: 'day',
    limitDay: 0,
    limitMonth: 0,

    typeSortDeposit: 'day',
    limitDayDeposit: 0,
    limitMonthDeposit: 0,

    adsAccounts: {
      totalAdsAccounts: 0,
      monthlyTotalAdsAccount: 0,
      monthlyStatisticsAdsAccount: [],
    },

    deposits: {
      totalSpend: 0,
    },
  },
  getters,
  mutations,
  actions,
}
