import { DEFAULT_GETTERS } from '@/constants'

export default {
  ...DEFAULT_GETTERS,
  isLoggedIn: state => state.isLoggedIn,
  user: state => state.user,
  userReports: state => state.userReports,
  generalConfigs: state => state.generalConfigs,
  plansStatus: state => state.plansStatus,
  shopifyVerified: state => state.shopifyVerified,
  isTikTokAppCenter: state => state.isTikTokAppCenter,
  isAuthenticated: state => state.isAuthenticated,
  dataAccessToken: state => state.dataAccessToken,
  notifications: state => state.notifications,
}
