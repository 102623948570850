import { DEFAULT_STATE } from '@/constants'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {
  namespaced: true,
  state: {
    ...DEFAULT_STATE,
    pixel: {
      content: [],
      paging: {
        total: 0,
        page: 1,
      },
    },
    detail: {},
    queryPixelData: {
      name: '',
      adsAccountId: '',
      error: '',
    },
    addNewStoreUrl: {
      url: '',
      error: '',
    },
    generatePixelData: {
      error: '',
    },
    checkEnabledEmbed: {
      loading: false,
      enabled: false,
    },
    enableExtension: {},
    retrieveSettings: {
      loading: false,
    },
  },
  getters,
  mutations,
  actions,
}
