/* eslint-disable import/prefer-default-export */
export const removeItemByIndex = (items, i) => items.slice(0, i).concat(items.slice(i + 1, items.length))

export const showLoading = () => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'block'
    appLoading.style.position = 'fixed'
  }
}

export const hideLoading = () => {
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
}

export const checkFieldValueExists = (field, value, array) => {
  const isExist = array.find(item => item[field] === value)
  return !!isExist
}

export const checkSizeFile = ({ value, maxValue, type }) => {
  let maxValueTyped = 0
  let pow = 0
  switch (type) {
    case 'kb': pow = 1; break
    case 'mb': pow = 2; break
    default:
  }

  maxValueTyped = maxValue * (1024 ** pow)

  return value <= maxValueTyped
}

export const setCookie = route => {
  if (route.query?.utm_source) {
    document.cookie = `utm_source=${route.query?.utm_source}`
  }
  if (route.query?.utm_medium) {
    document.cookie = `utm_medium=${route.query?.utm_medium}`
  }
}

export const getCookie = cookieName => {
  const name = `${cookieName}=`
  const decodedCookie = decodeURIComponent(document.cookie)
  const listCookie = decodedCookie.split(';')
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < listCookie.length; i++) {
    let cookie = listCookie[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ''
}

export const deleteCookie = name => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

// Update variables in root css
export const loadThemeConfig = (values = {}) => {
  const root = document.querySelector(':root')
  const variables = Object.keys(values)
  variables.forEach(variable => {
    root.style.setProperty(variable, values[variable])
  })
}
