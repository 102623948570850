import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import auth from './auth'
import file from './file'
import adsAccount from './ads-account'
import payment from './payment'
import billing from './billing'
import productLinks from './product-links'
import referralProgram from './referral-program'
import giftCode from './gift-code'
import pixel from './pixel'
import partnerShip from './partner-ship'
import referralProgramsNew from './referral-programs-new'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    file,
    adsAccount,
    payment,
    billing,
    productLinks,
    referralProgram,
    giftCode,
    pixel,
    partnerShip,
    referralProgramsNew,
  },
  strict: process.env.DEV,
})
