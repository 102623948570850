import i18n from '@/libs/i18n'

export default [
  {
    path: '/affiliate-program',
    name: 'affiliate-program',
    component: () => import('@/views/referral-programs-new/referral-programs-new.vue'),
    meta: {
      pageTitle: i18n.t('layout.textAffiliateProgram'),
      layout: 'vertical',
      requireAuth: true,
    },
  },
]
