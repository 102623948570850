import i18n from '@/libs/i18n'

export default [
  {
    path: '/referral-introduce',
    name: 'referral-introduce',
    component: () => import('@/views/referral-program/referral-introduce/ReferralIntrodue.vue'),
    meta: {
      pageTitle: i18n.t('layout.textReferral'),
      layout: 'vertical',
      requireAuth: true,
    },
  },
  {
    path: '/referral-partner-info',
    name: 'referral-partner-info',
    component: () => import('@/views/referral-program/referral-info/ReferralInfo.vue'),
    meta: {
      pageTitle: i18n.t('partnerContactPage.titlePage'),
      layout: 'vertical',
      requireAuth: true,
    },
  },
]
