import { ProductLinks } from '@/services'
import { getResponse } from '@/plugins/store'

export default {
  async createProductLink({ commit }, params) {
    commit('CREATE_PRODUCT_LINK_REQUEST')
    try {
      const { data } = await ProductLinks.createProductLink(params)
      commit('CREATE_PRODUCT_LINK_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('CREATE_PRODUCT_LINK_FAILURE', error)
    }
  },

  async createProductVideo({ commit }, params) {
    commit('CREATE_PRODUCT_VIDEO_REQUEST')
    try {
      const { data } = await ProductLinks.createProductVideo(params)
      commit('CREATE_PRODUCT_VIDEO_SUCCESS', getResponse(data) || [])
    } catch (error) {
      commit('CREATE_PRODUCT_VIDEO_FAILURE', error)
    }
  },
}
