import { showLoading, hideLoading } from '@/libs/common'
import auth from '@auth'
// eslint-disable-next-line import/no-cycle
import { appCenter } from '@/router'

export const REQUEST = (state, isShowLoading = false) => {
  state.loading = true
  state.error = ''
  if (isShowLoading) {
    showLoading()
  }
}

export const SUCCESS = (state, isShowLoading = false) => {
  state.status = true
  state.loading = false
  state.error = ''
  if (isShowLoading) {
    hideLoading()
  }
}

export const FAILURE = (state, error, isShowLoading = false) => {
  state.loading = false
  state.status = false
  state.error = error
  const token = auth.getToken()
  if (error.status === 401 && !!token) {
    auth.clearLocalStorage()
    if (state.isTikTokAppCenter) {
      appCenter.redirectToAuth('MAPI')
    } else if (process.env.VUE_APP_LOGIN_URL) {
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}/logout`
    } else {
      window.location.href = '/login'
    }
  }

  if (isShowLoading) {
    hideLoading()
  }
}

export const getResponse = data => data.result
