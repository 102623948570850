import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@auth'
import ACApp, { ERROR_MSG } from '@tiktok-service/app-center-connect'
// routes

// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { AuthService } from '@/services'
import { LIST_URL_REDIRECT } from '@/constants'
import { setCookie } from '@/libs/common'
import i18n from '@/libs/i18n'
import authRoute from './routes/auth'
import dashboardRoute from './routes/dashboard'
import paymentRoute from './routes/payment'
import errorRoute from './routes/error'
import adsAccount from './routes/adsAccount'
import billing from './routes/billing'
import pricing from './routes/pricing'
import partnerManagement from './routes/partnerManagement'
import referralProgram from './routes/referralProgram'
import pixelManagement from './routes/pixelManagement'
import referralProgramNew from './routes/referralProgramsNew'

export const appCenter = new ACApp(process.env.VUE_APP_TIKTOK_APP_CENTER)

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...authRoute,
    ...dashboardRoute,
    ...paymentRoute,
    ...errorRoute,
    ...adsAccount,
    ...pricing,
    ...billing,
    ...partnerManagement,
    ...referralProgram,
    ...pixelManagement,
    ...referralProgramNew,
  ],
})

// ? For splash screen

let appLoaded = false

const handleRequestTikTokAppCenter = async (params, to, from, next) => {
  if (to.name !== 'user-block') {
    try {
      const res = await AuthService.requestTikTokAppCenter(params)
      const { accessToken } = res.data.result
      if (accessToken) {
        localStorage.setItem('id_token', accessToken)
      }
    } catch (err) {
      if (err?.data?.messageCode === 'error.user_was_blocked') {
        return
      }
      if (err?.data?.messageCode === 'error.need_to_verify_email') {
        next('/verify-email-by-code')
      } else if (err?.data?.messageCode === 'error.user_not_found') {
        appCenter.redirectToAuth('MAPI')
      }
    }
  }
}

const initApp = async (to, from, next) => {
  const authCode = to.query.auth_code
  try {
    const sessionToken = await appCenter.getSessionToken()
    if (sessionToken && !appLoaded) {
      localStorage.removeItem('id_token')
      await store.dispatch('auth/setIsTikTokAppCenter', !!sessionToken)
      if (to.path !== '/verify-email-by-code') {
        await handleRequestTikTokAppCenter({ sessionToken, ...(authCode && { authCode }) }, to, from, next)
      }
    }
  } catch (err) {
    // Normal user
    if (err.message === ERROR_MSG.getSessionToken.timeout) {
      // handle timeout error
    }
    if (err.message === ERROR_MSG.getSessionToken.failed) {
      // console.log(e.message)
    }
    if (err.message === ERROR_MSG.redirectToAuth.unsupported) {
      // console.log(e.message)
    }
    if (err.message === ERROR_MSG.requestRedirectionTo) {
      // console.log(e.message)
    }
  }

  appLoaded = true
}

const loadLanguage = ({ pancakeUserId }) => {
  const lang = localStorage.getItem('locale')

  if (!lang && pancakeUserId) {
    const { language } = navigator

    if (language.includes('vi')) {
      localStorage.setItem('locale', 'vi')
      i18n.locale = 'vi'
    } else {
      localStorage.setItem('locale', 'en')
      i18n.locale = 'en'
    }
  }
}

router.beforeEach(async (to, from, next) => {
  // <!-- Get Session Token -->
  if (process.env.VUE_APP_PLATFORM_NAME === 'Ecomdy' && !appLoaded) {
    await initApp(to, from, next)
  }

  const isTikTokAppCenter = store.getters['auth/isTikTokAppCenter']

  /* --- this code check auto login for shopify ---*/
  const { query } = to
  const { session } = query
  let pancakeData
  if (session) {
    pancakeData = JSON.parse(atob(session))
  }

  const idToken = query.id_token ?? pancakeData?.id_token
  const pancakeUserId = query.pancakeUserId ?? pancakeData?.pancakeUserId
  const pancakePageId = pancakeData?.pancakePageId
  const loginUrl = process.env.VUE_APP_LOGIN_URL

  if (pancakeUserId) {
    loadLanguage({ pancakeUserId })
  }

  if (pancakePageId) {
    localStorage.setItem('pancakePageId', pancakePageId)
  }

  if (idToken) {
    localStorage.setItem('id_token', idToken)
    setCookie(to)
    delete query.id_token
    delete query.session

    next({ path: to.path, query, replace: true })
  } else if (pancakeUserId) {
    auth.clearLocalStorage()
    window.location.href = `${loginUrl}/logout?session=${btoa(JSON.stringify({ pancakeUserId: pancakeData?.pancakeUserId }))}`
  }

  /* --- this code check auto login for shopify ---*/
  if (!idToken && loginUrl && !isTikTokAppCenter && LIST_URL_REDIRECT.some(record => record === to.path)) {
    window.location.href = `${loginUrl}${to.fullPath}`
    // eslint-disable-next-line consistent-return
    return next()
  }

  await store.dispatch('auth/setIsAuthenticated', !!auth.getToken())

  // this page required not logged in to access, ex: login, register, reset, forgot password, v..v
  const requireNotAuth = await to.matched.some(record => record.meta.requireNotAuth)

  // redirect to login page if not logged in and trying to access a restricted page
  const requireAuth = await to.matched.some(record => record.meta.requireAuth)

  // this page require has plan
  // const requireUpdateInfo = await to.matched.some(record => record.meta.requireUpdateInfo)
  if (store.getters['auth/isAuthenticated']) {
    if (window !== window.top && !isTikTokAppCenter) {
      auth.clearLocalStorage()
      next({ name: 'register' })
    } else if (requireNotAuth && to.name !== 'reset-password') {
      next({ name: 'dashboard', replace: true })
    }
  } else if (requireAuth && !isTikTokAppCenter) {
    if (loginUrl) {
      if (pancakeUserId) {
        window.location.href = `${loginUrl}/logout?session=${btoa(JSON.stringify({ pancakeUserId, pancakePageId }))}`
      } else {
        window.location.href = `${loginUrl}/logout`
      }
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } })
    }
  }

  // eslint-disable-next-line consistent-return
  return next()
})

// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
